<template>
  <v-container>
    <v-layout justify-center>
      <v-dialog v-model="overlay" persistent max-width="600">
        <v-card>
          <v-card-text class="pt-5">
            <v-progress-linear
              indeterminate
              color="primary"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-dialog
      v-model="erroreSvincolo"
      @keydown.esc="erroreSvincolo = false"
      height="120"
      width="350"
    >
      <v-card>
        <v-card-title>
          <b>ERRORE NELLO SVINCOLO GIACENZA</b>
        </v-card-title>
        <v-card-text>
          Non è stato possibile svincolare la giacenza.<br />
          Si prega di riprovare tra qualche minuto e nel caso non si riuscisse
          contattare l'assistenza.
        </v-card-text>
        <v-card-actions>
          <v-btn @click="erroreSvincolo = false" depressed color="primary"
            >CHIUDI</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="mostraGiacenzaDialog"
      persistent
      max-width="800"
      @keydown.esc="mostraGiacenzaDialog = false"
    >
      <v-form v-model="validGia">
        <v-card>
          <v-card-title>
            <b>{{ $t("key701") }} {{ numeroSpedizioneVisualizzato }}</b>
          </v-card-title>
          <v-card-text>
            <v-textarea
              v-model="svincoloGia"
              :label="$t('key702')"
              value
              :height="250"
              :rules="[
                (v) =>
                  ValidaStringa(v) ||
                  'Inserire almeno 3 caratteri non identici',
              ]"
            ></v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-btn
              @click="mostraGiacenzaDialog = false"
              color="#A0A4A7"
              style="color: white"
              depressed
              >{{ $t("key453") }}</v-btn
            >
            <v-btn
              :disabled="!validGia"
              @click="Svincola"
              depressed
              color="primary"
              >{{ $t("key452") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog
      v-model="mostraFotoRulliera"
      persistent
      max-width="800"
      @keydown.esc="mostraFotoRulliera = false"
    >
      <v-card>
        <v-card-actions>
          <v-btn
            @click="EstraiImmaginiRulliera"
            color="#A0A4A7"
            style="color: white"
            depressed
            >{{ $t("key353") }}</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            fab
            dark
            small
            color="primary"
            @click="mostraFotoRulliera = false"
          >
            X
          </v-btn>
          <!-- <v-btn @click="mostraFotoRulliera = false">x</v-btn> -->
        </v-card-actions>
        <v-card-text>
          <v-carousel
            :cycle="false"
            :show-arrows="true"
            :hide-delimiters="true"
            height="altezzaRulliera"
          >
            <v-carousel-item v-for="(item, i) in foto" :key="i">
              <img
                :src="item.src"
                style="width: 770px; height: auto"
                :alt="item.src"
              />
            </v-carousel-item>
          </v-carousel>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="mostraPod"
      persistent
      max-width="980px"
      @keydown.esc="mostraPod = false"
    >
      <v-card>
        <v-card-actions>
          <v-btn
            @click="EstraiImmagini"
            color="#A0A4A7"
            style="color: white"
            depressed
            >{{ $t("key353") }}</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn fab dark small color="primary" @click="mostraPod = false">
            X
          </v-btn>
        </v-card-actions>
        <v-card-text>
          <v-carousel
            :cycle="false"
            :show-arrows="true"
            :hide-delimiters="true"
            height="altezzaPod"
          >
            <v-carousel-item v-for="(item, i) in images" :key="i">
              <img
                :src="item.src"
                style="width: 770px; height: auto"
                :alt="item.src"
              />
            </v-carousel-item>
          </v-carousel>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="mostraRimessi" persistent>
      <v-carousel :cycle="false" height="400">
        <v-carousel-item
          v-for="(item, i) in images"
          :key="i"
          :src="item.src"
        ></v-carousel-item>
      </v-carousel>
      <v-btn>{{ $t("key353") }}</v-btn>
      <v-spacer></v-spacer>
      <v-btn fab dark small color="primary" @click="mostraRimessi = false">
        X</v-btn
      >
    </v-dialog>

    <v-container grid-list-xl>
      <v-row>
        <v-col cols="12">
          <!-- <a href="#/tracking/"><-- {{$t('key339')}}</a> -->
          <a @click="$router.go(-1)"><-- Torna alla lista spedizioni</a>
        </v-col>

        <a name="top" />
        <v-col cols="12" md="6">
          <v-text-field
            :label="$t('key046')"
            v-model="numeroSpedizioneVisualizzato"
            readonly
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            :label="$t('key048')"
            v-model="dataSpedizione"
            readonly
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <v-row>
        <v-col cols="12" md="6">
          <v-sheet color="grey lighten-3" height="265" class="pa-2">
            <v-row>
              <v-col cols="12">
                <h2>{{ $t("key340") }}</h2>
                <v-text-field
                  :label="$t('key006')"
                  v-model="ragioneSocialeMittente"
                  readonly
                ></v-text-field>
                <v-text-field
                  :label="$t('key007')"
                  v-model="indirizzoMittente"
                  readonly
                ></v-text-field>
                <v-text-field
                  :label="$t('key008')"
                  v-model="localitaMittente"
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
          </v-sheet>
        </v-col>

        <v-col cols="12" md="6">
          <v-sheet color="grey lighten-3" height="265" class="pa-2">
            <v-row>
              <v-col cols="12">
                <h2>{{ $t("key341") }}</h2>
                <v-text-field
                  :label="$t('key006')"
                  v-model="ragioneSocialeDestinatario"
                  readonly
                ></v-text-field>
                <v-text-field
                  :label="$t('key007')"
                  v-model="indirizzoDestinatario"
                  readonly
                ></v-text-field>
                <v-text-field
                  :label="$t('key008')"
                  v-model="localitaDestinatario"
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
          </v-sheet>
        </v-col>

        <v-col cols="12" md="5">
          <v-text-field
            :label="$t('key342')"
            v-model="dataUltimoStato"
            readonly
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="5">
          <v-text-field
            :label="$t('key343')"
            v-model="descrizioneUltimoStato"
            readonly
            style="font-weight: bold"
          >
            <strong></strong>
          </v-text-field>
        </v-col>
        <!-- <v-flex xs12 md2>
        </v-flex>-->
        <v-col cols="12" md="2" class="text-md-right">
          <v-btn
            v-if="mostraGiacenza"
            @click="
              numeroGff == 0 ? (mostraGiacenzaDialog = true) : SvincolaGff()
            "
            >{{ $t("key636") }}</v-btn
          >
          <a href="#dettagli" style="font-size: 14px">
            {{ $t("key347") }}
            <v-icon>mdi-arrow-down</v-icon>
          </a>
        </v-col>
      </v-row>
    </v-container>
    <v-layout justify-center>
      <v-dialog v-model="acquista" persistent max-width="400">
        <v-card>
          <v-card-title class="headline">Visualizza POD</v-card-title>
          <v-card-text
            >Gentile utente, confermi di voler scaricare la documentazione
            elettronica per questa spedizione?Procedendo con questa operazione
            verranno applicate le clausole contrattuali</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              style="color: white"
              color="#A0A4A7"
              @click="acquista = false"
              depressed
              >Annulla</v-btn
            >
            <v-btn
              color="primary"
              dark
              depressed
              @click="AcquistoPod"
              :loading="controlloAcquisto"
              style="box-shadow: none"
              >Accetta</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout justify-center>
      <v-dialog v-model="acquistaRim" persistent max-width="400">
        <v-card>
          <v-card-title class="headline">Visualizza Rimessi</v-card-title>
          <v-card-text
            >Gentile utente, confermi di voler scaricare la documentazione
            elettronica per questa spedizione? Procedendo con questa operazione
            verranno applicate le clausole contrattuali</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              style="color: white"
              color="#A0A4A7"
              depressed
              @click="acquistaRim = false"
              >Annulla</v-btn
            >
            <v-btn color="primary" depressed @click="AcquistoRimessi"
              >Accetta</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-container>
      <v-row>
        <v-col
          cols="12"
          v-if="
            (numeroPod > 0 && bloccato != 'S') ||
            (linkPresente && bloccato != 'S')
          "
        >
          <v-row style="font-size: 14px">
            <v-col cols="12">
              {{ $t("key348") }}
              <span v-if="numeroPod > 0 && bloccato != 'S'">
                <v-icon
                  v-for="i in numeroPod"
                  v-bind:key="i"
                  @click="OpenPod(i)"
                  >mdi-file-document</v-icon
                ></span
              >
              <span v-if="linkPresente">
                <v-icon
                  v-for="i in link_POD.length"
                  v-bind:key="i"
                  @click="OpenPod(i - 1)"
                  >mdi-file-document</v-icon
                >
              </span>
            </v-col>
            <!-- <td v-if="linkPresente"> <v-icon  @click="OpenPod(1)">mdi-file-document</v-icon></td> -->

            <!-- <td > <v-icon @click="OpenPod">mdi-file-document</v-icon></td> -->
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" :hidden="mostraAcquistoRimessi">
          <v-row style="font-size: 14px">
            <v-col cols="12">
              <span v-if="bloccato != 'S'">{{ $t("key349") }}</span>
              <v-icon v-if="bloccato != 'S'" @click="OpenRimessi"
                >mdi-file-document</v-icon
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" :hidden="mostraRulliera">
          <v-row style="font-size: 14px">
            <v-col cols="12">
              <span>{{ $t("key350") }}</span>
              <v-icon @click="OpenRulliera"
                >mdi-camera</v-icon
              >
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <h2>{{ $t("key344") }}</h2>
        </v-col>
      </v-row>
      <v-container>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              :label="$t('key163')"
              v-model="colli"
              readonly
              flat
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              :label="$t('key345')"
              v-model="peso"
              readonly
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
      <v-container>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              :label="$t('key040')"
              v-model="ddtCliente"
              readonly
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              :label="$t('key346')"
              v-model="volume"
              readonly
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
      <v-container>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-if="mostraContrassegno"
              label="Contrassegno"
              v-model="contrassegno"
              readonly
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-if="mostraPreavviso"
              label="Preavviso telefonico"
              v-model="preavviso"
              readonly
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>

      <v-row>
        <v-col cols="12" sm="10">
          <a name="dettagli" />
          <v-data-table
            v-bind:headers="headers"
            v-bind:items="items"
            :items-per-page="25"
            :footer-props="{
              'items-per-page-options': [5, 10, 25, -1],
            }"
          >
            <template v-slot:[`item.Descrizione`]="{ item }">
              {{ item.Descrizione.split("|")[0] }}
              <br />
              {{ item.Descrizione.split("|")[1] }}
              <br />
              {{ item.Descrizione.split("|")[2] }}
            </template>
            <!-- <template slot="items" slot-scope="props">
              <tr>
                <td class="text-xs-left">{{ props.item.Data }}</td>
                <td class="text-xs-left">
                  {{ props.item.Descrizione.split("|")[0] }}
                  <br />
                  {{ props.item.Descrizione.split("|")[1] }}
                  <br />
                  {{ props.item.Descrizione.split("|")[2] }}
                </td>
                <td class="text-xs-left">{{ props.item.codiceCorris }}</td>
              </tr>
            </template> -->
            <!-- <template slot="pageText" slot-scope="{ pageStart, pageStop }"
              >From {{ pageStart }} to {{ pageStop }}</template
            > -->
          </v-data-table>
        </v-col>
        <v-col cols="12" md="2" class="text-md-right">
          <a href="#top">
            <v-icon>mdi-arrow-up</v-icon>
          </a>
          <!-- <a @click="history.replaceState({urlPath:'/'},'','/top')"><v-icon >arrow_upward</v-icon> </a> -->
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    bloccato: "",
    validGia: false,
    erroreSvincolo: false,
    altezzaPod: 570,
    altezzaRulliera: 570,
    svincoloGia: "",
    mostraGiacenzaDialog: false,
    mostraGiacenza: false,
    indicePod: 0,
    j: 0,
    y: 0,
    con: false,
    img: "",
    mostraContrassegno: false,
    preavviso: "",
    mostraPreavviso: "",
    contrassegno: "",
    descrizioneUltimoStato: "",
    mostraRulliera: true,
    mostraFotoRulliera: false,
    ultimoStato: "",
    mostraPod: false,
    mostraRimessi: false,
    numeroPod: 0,
    linkPresente: false,
    numeroGiacenza: "",
    dataUltimoStato: "",
    mostraAcquistoPod: true,
    mostraAcquistoRimessi: true,
    images: [],
    foto: [],
    overlay: false,
    acquistoPod: false,
    controlloAcquisto: false,
    acquistoRimessi: false,
    link_POD: [],
    acquista: false,
    acquistaRim: false,
    numeroSpedizione: "",
    numeroSpedizioneVisualizzato: "",
    dataSpedizione: "",
    ragioneSocialeMittente: "",
    indirizzoMittente: "",
    localitaMittente: "",
    ragioneSocialeDestinatario: "",
    indirizzoDestinatario: "",
    localitaDestinatario: "",
    colli: 0,
    peso: 0,
    adminRulliera: 0,
    ddtCliente: "",
    volume: 0,
    dataEvento: "",
    descrizioneEvento: "",
    spedizioneRiconsegna: "",
    items: [],
    stati: [],
    permessoPodRim: "",
    headers_it: [
      { text: "Data", align: "left", value: "Data" },
      { text: "Descrizione", align: "left", value: "Descrizione" },
      { text: "Codice Corrispondente", align: "left", value: "codiceCorris" },
    ],
    headers_en: [
      { text: "Date", align: "left", value: "Data" },
      { text: "Description", align: "left", value: "Descrizione" },
      { text: "Corresponding code", align: "left", value: "codiceCorris" },
    ],
    headers: [],
    headers_pod: [
      { text: "Nome file", align: "left", value: "nomeFile" },
      { text: "Link", align: "left", value: "link" },
    ],
    numeroGff: 0,
  }),
  created() {
    this.$store.dispatch("ImpostaScollegamento");
  },
  mounted() {
    if (this.$i18n.locale == "it") {
      this.headers = this.headers_it;
    } else {
      this.headers = this.headers_en;
    }
    this.CheckJWT();
    this.numeroServizio = this.$route.params.Nservizio;
    this.CheckAdminRulliera();
    if (this.numeroSpedizione !== 0 && this.numeroSpedizione !== "0") {
      this.ControllaBloccato();

      // this.descUltimoStato = this.items[0].split("|")[0]
    }
  },
  methods: {
    SvincolaGff() {
      window.open(
        "https://clienti.arcospedizioni.it/#/giacenzeGFF/" + this.numeroGff,
        "_blank"
      );
    },
    ControllaBloccato() {
      this.axios
        .get(this.$store.state.servicePath + "/api/Pod/CheckBloccato")
        .then((res) => {
          this.bloccato = res.data;
          this.getTrackingDetails();
        })
        .catch(() => {
          this.bloccato = "";
          this.getTrackingDetails();
        });
    },
    ValidaStringa(stringa) {
      if (stringa == null || stringa == undefined) {
        return false;
      }
      if (stringa.length < 3) {
        return false;
      }
      if (stringa[0] === stringa[1] && stringa[1] === stringa[2]) {
        return false;
      } else {
        return true;
      }
    },
    CheckAdminRulliera() {
      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/ArUtentiPermessis/Check/ADM-VISRUL"
        )
        .then((res) => {
          this.adminRulliera = res.data;
        });
    },
    CheckGiacenza() {
      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/Ttrgc10f/" +
            this.numeroSpedizione.split("-->")[0].trim()
        )
        .then((res) => {
          var giac = res.data;
          this.axios
            .get(
              this.$store.state.servicePath +
                "/api/Giac210f/CheckIfGff/" +
                this.numeroSpedizione.split("-->")[0].trim()
            )
            .then((res) => {
              this.numeroGff = res.data;
              if (
                giac === true &&
                (this.descrizioneUltimoStato.startsWith("GIACENZA") ||
                  this.descrizioneUltimoStato.startsWith("STOCK ON HOLD"))
              ) {
                this.mostraGiacenza = true;
              } else {
                this.mostraGiacenza = false;
              }
            });
        });
    },
    ContaRulliera() {
      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/Pod/Rulliera/" +
            this.numeroSpedizione.split("-->")[0].trim()
        )
        .then((res) => {
          if (res.data === 1) {
            this.mostraRulliera = false;
          }
        });
      if (this.spedizioneRiconsegna != "" && this.mostraRulliera !== false) {
        this.axios
          .get(
            this.$store.state.servicePath +
              "/api/Pod/Rulliera/" +
              this.spedizioneRiconsegna
          )
          .then((res) => {
            if (res.data === 1) {
              this.mostraRulliera = false;
            }
          });
      }
      if (this.mostraRulliera !== false && this.adminRulliera === 1) {
        this.axios
          .get(
            this.$store.state.servicePath +
              "/api/Pod/RullieraAdmin/" +
              this.numeroSpedizione.split("-->")[0].trim()
          )
          .then((res) => {
            if (res.data === 1) {
              this.mostraRulliera = false;
            }
          });
        if (this.spedizioneRiconsegna != "" && this.mostraRulliera !== false) {
          this.axios
            .get(
              this.$store.state.servicePath +
                "/api/Pod/RullieraAdmin/" +
                this.spedizioneRiconsegna
            )
            .then((res) => {
              if (res.data === 1) {
                this.mostraRulliera = false;
              }
            });
        }
      }
    },
    OpenRulliera() {
      this.foto = [];

      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/Pod/Rulliere/Download/" +
            this.numeroSpedizione.split("-->")[0].trim()
        )
        .then((res) => {
          if (res.data.length != 0)
            this.altezzaRulliera = Number(res.data[0].nomeFile);
          else {
            this.altezzaRulliera = 0;
          }
          res.data.forEach((element) => {
            this.foto.push({
              src:
                "data:image/" + element.estensione + ";base64," + element.src,
            });
          });
          if (this.spedizioneRiconsegna != "") {
            this.axios
              .get(
                this.$store.state.servicePath +
                  "/api/Pod/Rulliere/Download/" +
                  this.spedizioneRiconsegna
              )
              .then((res) => {
                if (res.data.length != 0)
                  this.altezzaRulliera = Number(res.data[0].nomeFile);
                else {
                  this.altezzaRulliera = 0;
                }
                res.data.forEach((element) => {
                  this.foto.push({
                    src:
                      "data:image/" +
                      element.estensione +
                      ";base64," +
                      element.src,
                  });
                });
              });
          }
          this.mostraFotoRulliera = true;
        });
    },
    ContaRimessi() {
      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/Pod/Rimessi/" +
            this.numeroSpedizione.split("-->")[0].trim()
        )
        .then((res) => {
          if (res.data > 0) {
            this.mostraAcquistoRimessi = false;
          } else {
            this.mostraAcquistoRimessi = true;
          }
        });
      if (
        this.spedizioneRiconsegna != "" &&
        this.mostraAcquistoRimessi !== false
      ) {
        this.axios
          .get(
            this.$store.state.servicePath +
              "/api/Pod/Rimessi/" +
              this.spedizioneRiconsegna
          )
          .then((res) => {
            if (res.data > 0) {
              this.mostraAcquistoRimessi = false;
            } else {
              this.mostraAcquistoRimessi = true;
            }
          });
      }
    },
    ContaPod() {
      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/Pod/" +
            this.numeroSpedizione.split("-->")[0].trim()
        )
        .then((res) => {
          this.numeroPod = Number(res.data);
          if (res.data > 0) {
            this.mostraAcquistoPod = false;
          } else {
            if (this.link_presente === false) {
              this.mostraAcquistoPod = true;
            }
          }
          if (
            this.spedizioneRiconsegna != "" &&
            this.mostraAcquistoPod !== false
          ) {
            this.axios
              .get(
                this.$store.state.servicePath +
                  "/api/Pod/" +
                  this.spedizioneRiconsegna
              )
              .then((res) => {
                if (res.data > 0) {
                  this.mostraAcquistoPod = false;
                  this.numeroPod += Number(res.data);
                } else {
                  if (this.link_presente === false) {
                    this.mostraAcquistoPod = true;
                  }
                }
                if (this.numeroPod === 0) {
                  this.ContaPodHeppner();
                }
              });
          }
        });
    },
    ContaPodHeppner() {
      var POD = [];
      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/Pod/Heppner/" +
            this.numeroSpedizione.split("-->")[0].trim()
        )
        .then((res) => {
          if (res.data.length > 0) {
            this.mostraAcquistoPod = false;
            this.linkPresente = true;

            res.data.forEach((el) => {
              POD.push(el);
            });
          } else {
            this.mostraAcquistoPod = true;
          }
        });
      if (this.spedizioneRiconsegna != "" && this.mostraAcquistoPod !== false) {
        this.axios
          .get(
            this.$store.state.servicePath +
              "/api/Pod/Heppner/" +
              this.spedizioneRiconsegna
          )
          .then((res) => {
            if (res.data.length > 0) {
              this.mostraAcquistoPod = false;
              this.linkPresente = true;
              res.data.forEach((el) => {
                POD.push(el);
              });
              //this.link_POD = res.data
            } else {
              this.mostraAcquistoPod = true;
            }
          });
      }
      this.link_POD = POD;
    },
    getTrackingDetails() {
      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/ArTrackingDetails/" +
            this.numeroServizio
        )
        .then((res) => {
          this.numeroSpedizione = res.data.arTracking[0].codiceSpedizione;
          try {
            this.numeroSpedizioneVisualizzato =
              this.numeroSpedizione.split("-->")[0].trim().substr(0, 4) +
              "-" +
              this.numeroSpedizione.split("-->")[0].trim().substr(4, 3) +
              "-" +
              this.numeroSpedizione.split("-->")[0].trim().substr(7, 8) +
              "-->" +
              this.numeroSpedizione.split("-->")[1].trim().substr(0, 4) +
              "-" +
              this.numeroSpedizione.split("-->")[1].trim().substr(4, 3) +
              "-" +
              this.numeroSpedizione.split("-->")[1].trim().substr(7, 8);
          } catch (e) {
            this.numeroSpedizioneVisualizzato =
              this.numeroSpedizione.substr(0, 4) +
              "-" +
              this.numeroSpedizione.substr(4, 3) +
              "-" +
              this.numeroSpedizione.substr(7, 8);
          }

          try {
            this.spedizioneRiconsegna = res.data.arTracking[0].codiceSpedizione
              .split("-->")[1]
              .trim();
          } catch (e) {
            this.spedizioneRiconsegna = "";
          }
          if (res.data.arTracking[0].noteStato != null) {
            this.descrizioneUltimoStato =
              res.data.arTracking[0].noteStato.split("|")[0];
            // if (res.data.arTracking[0].ultimoStato === "NOC") {
            //   this.descrizioneUltimoStato = "MERCE NON CONSEGNATA";
            // }
          }
          if (res.data.arTracking[0].dataStato != null) {
            this.dataUltimoStato =
              res.data.arTracking[0].dataStato.substr(0, 10).substr(8, 2) +
              "-" +
              res.data.arTracking[0].dataStato.substr(0, 10).substr(5, 2) +
              "-" +
              res.data.arTracking[0].dataStato.substr(0, 10).substr(0, 4);
          }
          this.dataSpedizione =
            res.data.arTracking[0].dataSpedizione.substr(6, 2) +
            "-" +
            res.data.arTracking[0].dataSpedizione.substr(4, 2) +
            "-" +
            res.data.arTracking[0].dataSpedizione.substr(0, 4);

          this.ragioneSocialeMittente =
            res.data.arTracking[0].ragioneSocialeMittente;
          this.indirizzoMittente = res.data.arTracking[0].indirizzoMittente;
          this.localitaMittente =
            res.data.arTracking[0].capMittente +
            " - " +
            res.data.arTracking[0].localitaMittente +
            " (" +
            res.data.arTracking[0].provinciaMittente +
            ")";
          this.ragioneSocialeDestinatario =
            res.data.arTracking[0].ragioneSocialeDestinatario;
          this.indirizzoDestinatario =
            res.data.arTracking[0].indirizzoDestinatario;
          this.localitaDestinatario =
            res.data.arTracking[0].capDestinatario +
            " - " +
            res.data.arTracking[0].localitaDestinatario +
            " (" +
            res.data.arTracking[0].provinciaDestinatario +
            ")";
          this.colli = res.data.arTracking[0].numeroColli;
          this.peso = res.data.arTracking[0].peso;
          this.ddtCliente = res.data.arTracking[0].riferimentoCliente2;
          this.volume = res.data.arTracking[0].volume;
          this.numeroGiacenza = res.data.arTracking[0].numeroGiacenza;
          this.contrassegno = res.data.arTracking[0].valContrassegno;
          this.preavviso = res.data.arTracking[0].preavvisoTelefonico;
          if (
            (this.contrassegno === null) |
            (this.contrassegno === undefined) |
            (this.contrassegno === "00000000000")
          ) {
            this.mostraContrassegno = false;
          } else {
            if (
              (this.contrassegno.trim() === "") |
              (this.contrassegno.trim() === "0")
            ) {
              this.mostraContrassegno = false;
            } else {
              this.mostraContrassegno = true;
            }
          }
          if ((this.preavviso === null) | (this.preavviso === undefined)) {
            this.mostraPreavviso = false;
          } else {
            if (
              (this.preavviso.trim() === "") |
              (this.preavviso.trim() === "0")
            ) {
              this.mostraPreavviso = false;
            } else {
              this.mostraPreavviso = true;
            }
          }

          if (res.data.arTrackingDetails.length > 0) {
            this.stati = [];
            this.con = false;
            var y = 0;
            if (
              this.numeroGiacenza.trim() === "" ||
              this.numeroGiacenza === null
            ) {
              for (y = 0; y < res.data.arTrackingDetails.length; y++) {
                if (res.data.arTrackingDetails[y].codEvento === "CON") {
                  this.stati = [];
                }
                this.stati.push(res.data.arTrackingDetails[y]);
              }
            } else {
              this.stati = [];
              this.con = false;
              var y = 0;
              for (y = 0; y < res.data.arTrackingDetails.length; y++) {
                this.el = {};
                if (
                  res.data.arTrackingDetails[y].descrizioneEvento.split("|")
                    .length > 0
                ) {
                  this.el = {
                    codiceSpedizione:
                      res.data.arTrackingDetails[y].codiceSpedizione,
                    dataEvento: res.data.arTrackingDetails[y].dataEvento,
                    oraEvento: res.data.arTrackingDetails[y].oraEvento,
                    tipoStatus: res.data.arTrackingDetails[y].tipoStatus,
                    codEvento: res.data.arTrackingDetails[y].codEvento,
                    descrizioneEvento:
                      res.data.arTrackingDetails[y].descrizioneEvento,
                    deleted: res.data.arTrackingDetails[y].deleted,
                    lastUpdate: res.data.arTrackingDetails[y].lastUpdate,
                    lastAction: res.data.arTrackingDetails[y].lastAction,
                    codiceCorris: res.data.arTrackingDetails[y].codiceCorris,
                  };

                  this.stati.push(this.el);
                } else {
                  this.stati.push(res.data.arTrackingDetails[y]);
                }
              }
            }
            this.noc = false;
            this.dataNC = "";
            this.con = false;
            this.stati.forEach((element) => {
              if (element.codEvento === "NOC" && this.noc === false) {
                var el = {
                  Data:
                    element.dataEvento.substr(6, 2) +
                    "/" +
                    element.dataEvento.substr(4, 2) +
                    "/" +
                    element.dataEvento.substr(0, 4),
                  //Descrizione: "MERCE NON CONSEGNATA",
                  Descrizione: element.descrizioneEvento,
                  codiceCorris: element.codiceCorris,
                };
                this.noc = true;
                this.dataNC = element.dataEvento;
                this.items.push(el);
              } else if (
                element.codEvento === "NOC" &&
                this.noc === true &&
                element.dataEvento != this.dataNC
              ) {
                var el = {
                  Data:
                    element.dataEvento.substr(6, 2) +
                    "/" +
                    element.dataEvento.substr(4, 2) +
                    "/" +
                    element.dataEvento.substr(0, 4),
                  //Descrizione: "MERCE NON CONSEGNATA",
                  Descrizione: element.descrizioneEvento,
                  codiceCorris: element.codiceCorris,
                };
                this.dataNC = element.dataEvento;
                this.items.push(el);
              } else if (element.codEvento === "CON" && this.con === false) {
                var el = {
                  Data:
                    element.dataEvento.substr(6, 2) +
                    "/" +
                    element.dataEvento.substr(4, 2) +
                    "/" +
                    element.dataEvento.substr(0, 4),
                  Descrizione: element.descrizioneEvento,
                  codiceCorris: element.codiceCorris,
                };
                this.con = true;
                this.items.push(el);
              } else if (
                element.codEvento !== "NOC" &&
                element.codEvento != "CON"
              ) {
                var el = {
                  Data:
                    element.dataEvento.substr(6, 2) +
                    "/" +
                    element.dataEvento.substr(4, 2) +
                    "/" +
                    element.dataEvento.substr(0, 4),
                  Descrizione: element.descrizioneEvento,
                  codiceCorris: element.codiceCorris,
                };

                this.items.push(el);
              }
            });
            //this.ContaPodHeppner();
            this.ContaPod();
            this.ContaRimessi();
            this.CheckAcquistiPod();
            this.CheckAcquistiRimessi();
            this.ContaRulliera();
            this.CheckGiacenza();
            this.CheckPPodRimessi();
          } else {
            this.items = [
              {
                Data: "",
                Descrizione: "",
                codiceCorris: "",
              },
            ];
            //this.ContaPodHeppner();
            this.ContaPod();
            this.ContaRimessi();
            this.CheckAcquistiPod();
            this.CheckAcquistiRimessi();
            this.ContaRulliera();
            this.CheckGiacenza();
            this.CheckPPodRimessi();
          }
        });
    },
    PodDownload() {
      this.images = [];

      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/Pod/Download/" +
            this.numeroSpedizione.split("-->")[0].trim()
        )
        .then((res) => {
          this.altezzaPod = res.data[0].nomeFile;
          res.data.forEach((element) => {
            this.images.push({
              src:
                "data:image/" + element.estensione + ";base64," + element.src,
            });
            this.img =
              "data:image/" + element.estensione + ";base64," + element.src;
          });
          if (this.spedizioneRiconsegna == "") {
            this.controlloAcquisto = false;
          }
        });
      if (this.spedizioneRiconsegna != "") {
        this.axios
          .get(
            this.$store.state.servicePath +
              "/api/Pod/Download/" +
              this.spedizioneRiconsegna
          )
          .then((res) => {
            res.data.forEach((element) => {
              this.images.push({
                src:
                  "data:image/" + element.estensione + ";base64," + element.src,
              });
            });
            this.controlloAcquisto = false;
          });
      }
    },
    RimessiDownload() {
      this.images = [];

      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/Pod/Download/Rimessi/" +
            this.numeroSpedizione.split("-->")[0].trim()
        )
        .then((res) => {
          if (res.data.length != 0) {
            // var pdf = res.data[0].src;
            // //window.open("data:application/pdf;base64, " + res.data);
            // var decodedString = atob(pdf);
            // var byteCharacters = decodedString;
            // var byteNumbers = new Array(byteCharacters.length);
            // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            //   for (var i = 0; i < byteCharacters.length; i++) {
            //     byteNumbers[i] = byteCharacters.charCodeAt(i);
            //   }
            //   var byteArray = new Uint8Array(byteNumbers);
            //   var blob = new Blob([byteArray], {
            //     type: "application/pdf"
            //   });
            //   window.navigator.msSaveOrOpenBlob(
            //     blob,
            //     "Rimessi" + this.numeroSpedizione.split("-->")[0].trim() + ".pdf"
            //   );
            var pdf = res.data[0].src;
            //window.open("data:application/pdf;base64, " + res.data);
            var decodedString = atob(pdf);
            var byteCharacters = decodedString;
            var byteNumbers = new Array(byteCharacters.length);
            // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            for (var i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            var byteArray = new Uint8Array(byteNumbers);
            var blob = new Blob([byteArray], {
              type: "application/pdf",
            });
            const fileObjectURL = URL.createObjectURL(blob);
            window.open(fileObjectURL);
          }

          //window.open("data:application/pdf;base64, " + pdf);
          // else {
          //   var pdfWindow = window.open(pdf, "_blank");
          //   pdfWindow.document.write(
          //     "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
          //       pdf +
          //       "'></iframe>"
          //   );
          // }
        });

      if (this.spedizioneRiconsegna != "") {
        this.axios
          .get(
            this.$store.state.servicePath +
              "/api/Pod/Download/Rimessi/" +
              this.spedizioneRiconsegna
          )
          .then((res) => {
            if (res.data.length != 0) {
              // var pdf = res.data[0].src;
              // //window.open("data:application/pdf;base64, " + res.data);
              // var decodedString = atob(pdf);
              // var byteCharacters = decodedString;
              // var byteNumbers = new Array(byteCharacters.length);
              // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              //   for (var i = 0; i < byteCharacters.length; i++) {
              //     byteNumbers[i] = byteCharacters.charCodeAt(i);
              //   }
              //   var byteArray = new Uint8Array(byteNumbers);
              //   var blob = new Blob([byteArray], {
              //     type: "application/pdf"
              //   });
              //   window.navigator.msSaveOrOpenBlob(
              //     blob,
              //     "Rimessi" +
              //       this.numeroSpedizione.split("-->")[0].trim() +
              //       ".pdf"
              //   );
              var pdf = res.data[0].src;
              //window.open("data:application/pdf;base64, " + res.data);
              var decodedString = atob(pdf);
              var byteCharacters = decodedString;
              var byteNumbers = new Array(byteCharacters.length);
              // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              for (var i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
              }
              var byteArray = new Uint8Array(byteNumbers);
              var blob = new Blob([byteArray], {
                type: "application/pdf",
              });
              const fileObjectURL = URL.createObjectURL(blob);
              window.open(fileObjectURL);
            }

            //window.open("data:application/pdf;base64, " + pdf);
            //   else {
            //     var pdfWindow = window.open(pdf, "_blank");
            //     pdfWindow.document.write(
            //       "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
            //         pdf +
            //         "'></iframe>"
            //     );
            //   }
            //   }
          });
      }
    },
    CheckJWT() {
      this.axios
        .post(this.$store.state.servicePath + "/api/Login/checkJwt", {
          route: this.$route.path,
        })
        .then((res) => {
          if (res.data.status == "ko") {
            this.$router.push({ path: `/errore` });
          }
        })
        .catch(() => {
          this.$router.push({ path: `/errore` });
        });
    },
    OpenPod(i) {
      this.indicePod = i;
      if (!this.acquistoPod && this.permessoPodRim !== 1) {
        this.controlloAcquisto = false;
        this.acquista = true;
      } else {
        if (this.linkPresente) {
          this.controlloAcquisto = false;
          window.open(this.link_POD[i]);
        } else {
          this.PodDownload();
          this.mostraPod = true;
        }
        // this.axios
        // .get(this.$store.state.servicePath + "/api/ArTrackingLinks/" + this.numeroSpedizione, {
        // })
        // .then(res =>{
        //   this.mostraPod = true
        // window.open(res.data.link, '_blank')
        // })
      }
    },
    OpenRimessi() {
      if (!this.acquistoRimessi && this.permessoPodRim !== 1)
        this.acquistaRim = true;
      else {
        this.RimessiDownload();
        // this.mostraRimessi = true
      }
    },
    AcquistoPod() {
      this.controlloAcquisto = true;
      this.axios
        .post(this.$store.state.servicePath + "/api/ArTrackingAcquistis", {
          numeroBolla: this.numeroSpedizione.split("-->")[0].trim(),
          tipoAcquisto: "P",
        })
        .then(() => {
          this.acquista = false;
          this.acquistoPod = true;
          this.OpenPod(this.indicePod);
        });
    },
    AcquistoRimessi() {
      this.axios
        .post(this.$store.state.servicePath + "/api/ArTrackingAcquistis", {
          numeroBolla: this.numeroSpedizione.split("-->")[0].trim(),
          tipoAcquisto: "R",
        })
        .then(() => {
          this.acquistaRim = false;
          this.acquistoRimessi = true;
          this.OpenRimessi();
        });
    },
    CheckAcquistiPod() {
      this.axios
        .post(
          this.$store.state.servicePath +
            "/api/ArTrackingAcquistis/CheckAcquisti",
          {
            numeroBolla: this.numeroSpedizione.split("-->")[0].trim(),
            tipoAcquisto: "P",
          }
        )
        .then((res) => {
          this.acquistoPod = res.data;
        });
    },
    CheckAcquistiRimessi() {
      this.axios
        .post(
          this.$store.state.servicePath +
            "/api/ArTrackingAcquistis/CheckAcquisti",
          {
            numeroBolla: this.numeroSpedizione.split("-->")[0].trim(),
            tipoAcquisto: "R",
          }
        )
        .then((res) => {
          this.acquistoRimessi = res.data;
        });
    },
    EstraiImmagini() {
      this.indice = 0;
      this.images.forEach((e) => {
        let imgContent = e.src;
        var encodedUri = encodeURI(imgContent);
        var decodedString = atob(
          imgContent.replace("data:image/jpg;base64,", "")
        );
        var byteCharacters = decodedString;
        var byteNumbers = new Array(byteCharacters.length);
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          var byteArray = new Uint8Array(byteNumbers);
          var blob = new Blob([byteArray], {
            type: "image/jpg",
          });
          navigator.msSaveBlob(
            blob,
            this.numeroSpedizione.split("-->")[0].trim() +
              "_" +
              this.indice +
              ".jpg"
          );
        } else {
          const link = document.createElement("a");
          link.setAttribute("href", encodedUri);
          link.setAttribute(
            "download",
            this.numeroSpedizione.split("-->")[0].trim() +
              "_" +
              this.indice +
              ".jpg"
          );
          link.click();
        }
        this.indice += 1;
      });
    },
    EstraiImmaginiRulliera() {
      this.indice = 0;
      this.foto.forEach((e, i) => {
        setTimeout(() => {
          let imgContent = e.src;
          var encodedUri = encodeURI(imgContent);
          var decodedString = atob(
            imgContent.replace("data:image/jpg;base64,", "")
          );
          var byteCharacters = decodedString;
          var byteNumbers = new Array(byteCharacters.length);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            for (var i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            var byteArray = new Uint8Array(byteNumbers);
            var blob = new Blob([byteArray], {
              type: "image/jpg",
            });
            navigator.msSaveBlob(
              blob,
              this.numeroSpedizione.split("-->")[0].trim() +
                "_" +
                this.indice +
                ".jpg"
            );
          } else {
            const link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute(
              "download",
              this.numeroSpedizione.split("-->")[0].trim() +
                "_" +
                this.indice +
                ".jpg"
            );

            link.click();
          }
          this.indice += 1;
        }, i * 100);
      });
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(() => resolve, ms));
    },
    Svincola() {
      this.overlay = true;
      this.mostraGiacenzaDialog = false;
      this.axios
        .post(this.$store.state.servicePath + "/api/Mail", {
          numeroBolla: this.numeroSpedizione.split("-->")[0].trim(),
          motivazione1: this.svincoloGia,
          motivazione2: "",
        })
        .then((res) => {
          if (res.data == "ok") {
            this.mostraGiacenza = false;
            this.axios.post(this.$store.state.servicePath + "/api/Ttrgc10f", {
              numeroSped: this.numeroSpedizione.split("-->")[0].trim(),
            });
            this.overlay = false;
          } else {
            this.overlay = false;
            this.erroreSvincolo = true;
          }
        })
        .catch(() => {
          this.overlay = false;
          this.erroreSvincolo = true;
        });
    },
    CheckPPodRimessi() {
      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/ArUtentiPermessis/Check/VIS-PODRIM"
        )
        .then((res) => {
          this.permessoPodRim = res.data;
        });
    },
  },
};
</script>